@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

@layer components {
  /* `text-style-` prefix is used for orange-alpha */
  .text-style-desc {
    @apply text-base font-normal text-athensGray;
  }
  /* Body-1-md */
  .text-style-sub {
    @apply text-base font-medium text-white;
  }
  .text-style-info {
    @apply text-base font-medium text-darkGrayishblue;
  }
  .text-style-button {
    @apply text-base font-semibold text-white font-heading;
    &--sub {
      @apply text-base font-semibold text-white font-sans;
    }
  }
  .text-style-title {
    @apply text-5xl md:text-6xl lg:text-8xl font-bold text-white font-heading;
  }
  .text-style-heading {
    @apply text-4xl md:text-5xl lg:text-6xl font-medium text-white font-heading;
  }
  .text-style-heading2 {
    @apply text-3xl font-semibold text-white font-heading;
  }
  .text-style-subheading {
    @apply text-4xl font-normal text-white;
  }
  .text-style-subheading2 {
    @apply text-lg font-bold text-white;
  }
  .text-style-accent {
    @apply text-md font-semibold text-manatee;
  }
  .text-style-form {
    @apply text-md font-normal text-frenchGray;
  }
  /* Heading 6 */
  .text-style-value {
    @apply text-lg font-semibold text-white font-heading;
    &--supress {
      @apply text-frencGray;
    }
  }
  .text-style-label {
    @apply text-sm font-normal text-white;
  }
  /* Heading ４ */
  .text-style-card-heading {
    @apply text-4xl font-bold text-white font-heading;
  }
  /* Heading ４ */
  .text-style-heading4 {
    @apply text-lg font-semibold text-white;
  }
  /* Heading 5 */
  .text-style-heading5 {
    @apply text-lg text-white font-heading;
  }
  /* Heading 6 */
  .text-style-heading6 {
    @apply text-lg font-semibold text-white;
  }
  .text-style-heading5a {
    @apply text-lg font-medium text-manatee font-heading;
  }
  /* Body-0-sb */
  .text-style-body0-sb {
    @apply text-2xl font-semibold text-primary font-heading;
  }
  /* Body-1-sb */
  .text-style-body1-sb {
    @apply text-xl font-semibold text-primary font-heading;
  }
  /* Body-2-sb */
  .text-style-body2-sb {
    @apply text-lg font-semibold text-frenchGray;
  }
  /* Body-1-rg */
  .text-style-body1-rg {
    @apply text-xl font-normal text-white font-heading;
  }
  /* Body-2-rg */
  .text-style-body2-rg {
    @apply text-base font-normal text-white;
  }
  /* Body-3-rg */
  .text-style-body3-rg {
    @apply text-sm font-normal font-heading;
  }
  /* Body-3-md */
  .text-style-body3-md {
    @apply text-md font-medium text-trout font-heading;
  }
  /* Body-3-sb */
  .text-style-body3-sb {
    @apply text-md font-semibold text-lightPrimary font-heading;
  }
  /* Body-4-md */
  .text-style-body4-md {
    @apply text-sm text-white;
  }
  /* Body-4-rg */
  .text-style-body4-rg {
    @apply text-sm font-normal leading-5 text-white;
  }
  /* Body-5-rg-uppercase */
  .text-style-body5-rg {
    @apply text-xs font-normal text-white;
  }
  .text-spaceship-countdown {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.65) -0.33%,
      #ffffff 34.9%,
      rgba(255, 255, 255, 0.65) 99.67%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  /* strategy vaults */
  .type-style-label {
    @apply text-md font-medium dark:text-gray-300 font-heading;
  }
  .type-xs-caption {
    @apply text-xs font-semibold dark:text-gray-300 leading-3;
  }
  .type-xs-medium {
    @apply text-xs font-medium dark:text-gray-300 leading-3;
  }
  .type-sm-caption,
  .type-sm-semibold {
    @apply text-sm font-semibold dark:text-gray-300 leading-5;
  }
  .type-sm-regular {
    @apply text-sm font-normal dark:text-gray-200 leading-5;
  }
  .type-sm-medium {
    @apply text-sm font-medium dark:text-gray-200 leading-5;
  }
  .type-sm-bold {
    @apply text-sm font-bold dark:text-gray-200 leading-5;
  }
  .type-base-medium {
    @apply text-md font-medium dark:text-gray-200 leading-6;
  }
  .type-base-caption {
    @apply text-md font-semibold dark:text-gray-200 leading-6;
  }
  .type-base-semibold {
    @apply text-md font-semibold dark:text-gray-200 leading-6;
  }
  .type-base-bold {
    @apply text-md font-bold dark:text-gray-200 leading-4;
  }
  .type-lg-normal {
    @apply text-lg font-normal dark:text-gray-200 leading-6;
  }
  .type-lg-medium {
    @apply text-lg font-medium dark:text-gray-200 leading-7;
  }
  .type-lg-semibold {
    @apply text-lg font-semibold dark:text-gray-200 leading-7;
  }
  .type-xl-normal {
    @apply text-xl font-normal dark:text-gray-200 font-heading;
  }
  .type-xl-semibold {
    @apply text-xl font-semibold dark:text-gray-200 font-heading;
  }
  .type-2xl-medium {
    @apply text-2xl font-medium dark:text-gray-200 leading-8;
  }
  .type-2xl-semibold {
    @apply text-2xl font-semibold dark:text-gray-200 leading-8;
  }
  .type-2xl-bold {
    @apply text-2xl font-bold dark:text-gray-200 leading-8;
  }
  .type-3xl-semibold {
    @apply text-3xl font-semibold dark:text-gray-200 font-heading;
  }
  .type-3xl-bold {
    @apply text-3xl font-bold dark:text-gray-200 font-heading;
  }
  .type-4xl-semibold {
    @apply text-4xl font-semibold dark:text-gray-200 font-heading;
  }
  .type-4xl-bold {
    @apply text-4xl font-bold dark:text-gray-200 font-heading;
  }
}

@layer components {
  .bg-main {
    /* @apply bg-blackPearl; */
    @apply dark:bg-gray-900;
  }
  .bg-sub {
    @apply bg-white;
  }
  .bg-primary {
    @apply bg-vermilion;
  }
  .bg-secondary {
    @apply bg-brightGray;
  }
  .bg-capacity {
    --tw-gradient-from: #0c0d11;
    --tw-gradient-to: #282c38;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    @apply bg-gradient-to-r;
  }
  .bg-capacity-used {
    --tw-gradient-from: #ff7a41;
    --tw-gradient-to: #ff4d01;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    @apply bg-gradient-to-b;
  }
  .bg-component {
    @apply bg-darkGrayishblue;
    &--inactive {
      @apply bg-charade;
    }
  }
  .bg-text-field {
    @apply bg-darkGrayishblue;
  }
  .bg-info {
    @apply bg-brightGray;
  }
  .bg-error {
    @apply bg-brick;
  }
  .bg-tag {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #484b56;
  }
  .border-base {
    @apply border-trout;
  }
  .border-focus {
    @apply border-athensGray;
  }

  .product-list-item {
    .focused-item {
      display: none;
      position: absolute;
      right: 0;
      top: 5px;
    }
    &:hover {
      background: linear-gradient(
        90deg,
        rgba(30, 33, 42, 0) 0%,
        rgba(235, 255, 0, 0.15) 74.73%,
        rgba(250, 82, 160, 0.2) 100%
      );
      .focused-item {
        display: block;
      }
    }
  }

  button.animate-highlight {
    background: linear-gradient(-45deg, #fb5b0d, #ff8c3d, #fb5b0d, #d94d0b);
    background-size: 300% 300%;
    animation: highlight-pulse 2.8s ease infinite;
    transition: background 0.3s ease, background-size 0.3s ease;
  }
  @keyframes highlight-pulse {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

@layer components {
  html,
  body {
    overflow-x: hidden;
    @apply font-heading bg-main text-white h-full;
  }
  #__next {
    @apply h-full;
  }
}

@layer utilities {
  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }
  .list-item {
    position: relative;
    &:before {
      content: '';
      background-color: #fb5b0d;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: -16px;
    }
  }
  input[type='text']:focus {
    @apply outline-0 ring-0 ring-transparent outline-none shadow-none;
  }
}

.container {
  @apply md:max-w-screen-lg lg:max-w-screen-xl xl:max-w-screen-2xl px-5;
}

main.container-fluid {
  @apply flex-grow xl:px-60 lg:px-44 md:px-28;

  .content {
    @apply mx-auto max-w-screen-xl p-4;
  }

  .content-lg {
    @apply mx-auto max-w-screen-lg p-4;
  }

  .content-md {
    @apply mx-auto max-w-screen-md p-4;
  }

  .content-sm {
    @apply mx-auto max-w-screen-sm p-4;
  }
}

ol.alpha-list-number {
  counter-reset: item;

  li::before {
    @apply h-8 w-8 rounded-full bg-trout text-white flex items-center justify-center ml-4 font-mono;
    counter-increment: item;
    content: counter(item);
  }
}

ol.list-number {
  counter-reset: item;

  li::before {
    @apply h-[32px] w-[35px] box-content rounded-full bg-emerald-400 type-base-semibold text-gray-950 flex items-center justify-center;
    counter-increment: item;
    content: counter(item);
    transform: scale(1);
  }
}

.bg-uniswap {
  background: radial-gradient(
      49.23% 92.13% at -0.69% 100%,
      #ed22c0 0%,
      rgba(237, 34, 192, 0.3) 0.01%,
      rgba(237, 34, 192, 0) 100%
    )
    rgba(35, 6, 29, 0.7);
}

.deprecated {
  .bg-camelot {
    background: linear-gradient(
      90deg,
      rgba(255, 175, 29, 0.15) 15.63%,
      rgba(255, 175, 29, 0.04) 87.85%
    );
  }
}

.bg-camelot {
  background: linear-gradient(270deg, rgba(255, 175, 29, 0.24) 0%, rgba(255, 175, 29, 0.08) 100%);
}

.bg-dopex {
  background: linear-gradient(270deg, rgba(29, 160, 255, 0.24) 0%, rgba(29, 92, 255, 0.08) 100%);
}

.bg-stryke {
  background: linear-gradient(270deg, rgba(235, 255, 0, 0.24) 0%, rgba(235, 214, 28, 0) 100%),
    linear-gradient(0deg, #12151d, #12151d);
}

.bg-tag-uniswap {
  @apply bg-darkPurple;
}

.deprecated {
  .bg-tag-camelot {
    @apply bg-thinYellow;
  }
}

.bg-tag-camelot,
.bg-tag-dopex,
.bg-tag-stryke {
  background: rgba(255, 255, 255, 0.08);
}

.tippy-tooltip-content {
  text-align: start;
}

.tippy-tooltip {
  padding: 0;
  border-radius: 12px;
}
.tippy-popper[x-placement^='bottom'] [x-arrow] {
  border-bottom: 7px solid #11141c;
}
