.spinner {
  display: inline-block;
  overflow: hidden;
  height: 1.2rem;
  margin-top: -0.3rem;
  line-height: 1.5rem;
  vertical-align: text-bottom;

  &::after {
    display: inline-table;
    white-space: pre;
    text-align: left;
  }
}

.spinner.dots::after {
  content: "⠋\A⠙\A⠹\A⠸\A⠼\A⠴\A⠦\A⠧\A⠇\A⠏";
  animation: spin10 1s steps(10) infinite;
}

.spinner.line::after {
  content: "/\A–\A\\\A|";
  text-align: center;
  animation: spin4 1s steps(4) infinite;
}

.spinner.triangle::after {
  content: "◢\A◣\A◤\A◥";
  animation: spin4 1s steps(4) infinite;
}

@keyframes spin4 { to { transform: translateY(-6.0rem); } }
@keyframes spin10 { to { transform: translateY(-15.0em); } }
